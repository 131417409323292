<template>
    <div class="mon-lang-switcher">
        <UiButtonIcon :size="variant === 'compact' ? 'small' : 'extra-small'"
                      :aria-label="$t('accessibility.switch_locale')"
                      @click="openModal"
        >
            <span :class="variant === 'compact' ? 'h-[1.375rem] lg:h-[1.875rem]' : 'w-full h-full'" aria-hidden="true">
                <CoreImg
                    class="size-full object-cover"
                    :width="50"
                    :src="localeProperties.flag"
                    :alt="localeProperties.flagAlt"
                    loading="lazy"
                />
            </span>
        </UiButtonIcon>

        <BaseUiButton v-if="variant === 'full'"
                      variant="plain"
                      class="!p-0 !pl-4"
                      @click="openModal"
        >
            {{ localeProperties.name }}
            <template #trailing>
                <IconChevron height="7" width="15" rotate="left" />
            </template>
        </BaseUiButton>

        <!-- LANG SWITCHER MODAL -->
        <LazyLangSwitcherModal
            v-if="isModalMounted"
            v-model="isModalOpen"
        />
    </div>
</template>

<script lang="ts" setup>

const {
    variant = 'compact',
} = defineProps<{
    variant?: 'full' | 'compact'
}>()

const { localeProperties } = useI18n()

const { is: isModalOpen, was: isModalMounted } = useLazyMount()

function openModal() {
    isModalOpen.value = !isModalOpen.value
}

</script>

<style lang="scss" scoped>

.mon-lang-switcher {
    position: relative;

    display: flex;
    align-items: center;

    @include mouse-hover {
        text-decoration: underline;
        cursor: pointer;
    }
}

</style>
