<script lang="tsx">
import { defineComponentCoreIcon } from '@core/app/components/core/icon/CoreIcon.vue'

export default defineComponentCoreIcon(
    <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.95729 0H7.04271C7.42305 0 7.8461 0.092511 8.18373 0.229075C9.11322 0.594714 9.91661 1.4185 10.2542 2.42511C10.4231 2.92952 10.5085 3.47797 10.4658 3.98018C10.4231 4.71145 10.1708 5.44493 9.79051 6.03965C9.53627 6.35903 9.28407 6.63436 8.98712 6.86344C9.95932 7.22907 10.8888 7.9163 11.5641 8.78634C12.1559 9.47357 12.579 10.3414 12.7885 11.2577C12.9146 11.8062 13 12.3568 13 12.9053V12.9978H12.0705C12.0705 11.9449 11.7756 10.8921 11.2671 10.0242C11.0129 9.61233 10.718 9.2467 10.4231 8.97137C9.66237 8.19383 8.64746 7.68943 7.63458 7.55286C6.61966 7.4163 5.60678 7.64537 4.67729 8.14758C4.04271 8.51322 3.45288 9.01762 2.98712 9.65859C2.31186 10.6189 1.93153 11.8084 1.93153 13H1V12.9075C1 12.1762 1.1261 11.4427 1.38034 10.7577C1.71797 9.79736 2.22441 8.92731 2.94441 8.24009C3.53627 7.64537 4.25424 7.14097 5.05763 6.86784C4.80339 6.68502 4.55119 6.45595 4.33966 6.18062C3.83322 5.53965 3.53627 4.71586 3.53627 3.84581C3.53627 3.52643 3.57898 3.15859 3.66237 2.83921C3.83119 2.01542 4.29695 1.23789 4.92949 0.73348C5.52136 0.27533 6.23932 0 6.95729 0ZM6.74576 1.05286C5.98508 1.09912 5.26712 1.60132 4.84407 2.3348C4.54915 2.83921 4.42102 3.52423 4.50644 4.1652C4.59186 4.75991 4.84407 5.35463 5.26712 5.76652C5.73288 6.22467 6.28203 6.45374 6.87186 6.4978C7.3783 6.54405 7.88678 6.40529 8.3078 6.0859C8.81424 5.76652 9.19458 5.26211 9.4061 4.6674C9.57491 4.07269 9.57491 3.43172 9.4061 2.88326C9.19458 2.24229 8.81424 1.74009 8.3078 1.4185C7.84203 1.09912 7.29288 0.960352 6.74373 1.05286H6.74576Z"
            fill="currentColor"
        />
    </svg>
)

</script>

<style lang="scss" scoped>

</style>
