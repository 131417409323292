<template>
    <svg viewBox="0 0 358 338" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 167.023C0 271.694 84.3606 338 176.406 338C297.868 338 357.23 261.99 357.23 167.023C357.23 64.9586 281.173 0 175.7 0C71.5519 0 0 74.1228 0 167.023ZM74.997 46.3604C129.324 31.6257 284.618 199.997 298.487 252.736C304.052 274.12 298.221 287.057 281.614 291.64C230.998 305.386 74.5554 145.371 58.3899 83.8262C53.2665 64.3296 59.6266 50.4934 74.997 46.3604Z" fill="currentColor" />
    </svg>
</template>

<script lang="ts" setup>

</script>

<style lang="scss" scoped>

</style>
