<template>
    <div class="relative">
        <!--  Auth Button  -->
        <UiButtonIcon
            ref="userMenuButton"
            size="small"
            :aria-controls="userMenuId"
            :aria-expanded="isUserMenuDropdownOpen"
            :aria-label="userMenuButtonAriaLabel"
            :to="loggedIn ? undefined : localePath('login')"
            @click="toggleUserMenu"
        >
            <IconPerson class="h-[1.375rem] lg:h-[1.875rem]" aria-hidden="true" />
        </UiButtonIcon>

        <!--  USER MENU  -->
        <LazyUiPopupCard
            v-if="isUserMenuDropdownMounted && $refs.userMenuButton"
            :id="userMenuId"
            v-model="isUserMenuDropdownOpen"
            class="mon-customer-login__popup"
            size="sm"
            tip-location="top-right"
            :ignore-elements="[$refs.userMenuButton?.$el]"
        >
            <UiPopupCardList>
                <!--  MENU GROUP  -->
                <span class="mon-text-small mon-font-bold" aria-hidden="true">
                    {{ customer?.getFullName() }}
                </span>
                <span class="mon-text-extra-small">
                    {{ customer?.email }}
                </span>

                <hr class="my-4">

                <div
                    v-for="(batch, index) in userMenuItems"
                    :key="index"
                >
                    <template v-if="index == 1">
                        <hr class="my-4">
                    </template>

                    <!--  MENU ITEM  -->
                    <UiPopupCardListItem
                        v-for="(item, itemIndex) in batch"
                        :key="itemIndex"
                        :href="item.routeName ? localePath(item.routeName) : undefined"
                        :on-click="item.onClick"
                    >
                        <component
                            :is="iconComponents[item.labelPath]"
                            v-if="item.icon"
                            width="15"
                            height="16"
                            aria-hidden="true"
                        />


                        {{ $t(item.labelPath) }}
                    </UiPopupCardListItem>
                </div>
            </UiPopupCardList>
        </LazyUiPopupCard>

    </div>
</template>

<script lang="ts" setup>
import type { Component } from 'vue'

const { t } = useI18n()
const localePath = useLocalePath()

const { loggedIn, customer } = useAuth()

const { is: isUserMenuDropdownOpen, was: isUserMenuDropdownMounted } = useLazyMount()

const events = useEvents()
// ensure that the popup doesn't stay open after logging in
events.useOn('customer:logging-in', () => {
    isUserMenuDropdownOpen.value = false
})

// ensure that the popup correctly closes when logging out (before the customer is unset visually)
events.useOn('customer:logging-out', () => {
    isUserMenuDropdownOpen.value = false
})

const userMenuId = useId()
const userMenuButtonAriaLabel = computed(() => !loggedIn.value ? t('labels.log_in_short') : isUserMenuDropdownOpen.value
    ? t('accessibility.logged_in_user_button_close_menu', [customer.value?.getFullName()])
    : t('accessibility.logged_in_user_button_open_menu', [customer.value?.getFullName()])
)

function toggleUserMenu() {
    isUserMenuDropdownOpen.value = !isUserMenuDropdownOpen.value
}

const userMenuButton = ref<HTMLElement | null>(null)

const appConfig = useAppConfig()

type UserMenuItem = {
    onClick?: () => void
} & Omit<typeof appConfig.userMenu.links[0], 'routeName'> & {
    routeName?: string
}

const userMenuItems = computed<UserMenuItem[][]>(() => [
    appConfig.userMenu.links,
    [
        {
            labelPath: 'user.log_out',
            icon: 'IconLogOut',
            onClick: logOutCustomer,
        },
    ],
])

async function logOutCustomer() {
    await useAuth().logOut()
}

const iconComponents = computed<Record<string, Component>>(() =>
    Object.fromEntries(
        userMenuItems.value.flat().map(item => [item.labelPath, defineAsyncComponent(() => import(`../../icon/${item.icon}.vue`))])
    )
)

</script>

<style lang="scss" scoped>

.mon-customer-login__popup {
    position: absolute;
    bottom: -0.75rem;
    right: -5.25rem;
    width: 18.75rem;

    transform: translate(0%, 100%);

    @include more-than(lg) {
        right: -4rem; // $mon-navbar-item-spacing (1rem) + UiButtonIcon width (3rem)
    }
}

</style>
