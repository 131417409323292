<script lang="tsx">
import { defineComponentCoreIcon } from '@core/app/components/core/icon/CoreIcon.vue'

export default defineComponentCoreIcon(
    <svg viewBox="0 0 13 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.43061 0H9.8012C10.9032 0 11.9955 5.44398e-07 13 0.192819V4.29274H10.7082C10.162 4.29274 9.51838 4.38408 9.06001 4.76971C8.60165 5.15535 8.51388 5.72365 8.41635 6.29196V9.52927H12.805C12.6197 11.0515 12.4344 12.5738 12.2588 14.096H8.41635V26H3.84246V14.096H0V9.52927H3.84246V7.2459C3.84246 6.29196 3.84246 5.33802 4.02776 4.38407C4.30082 3.05464 4.94449 1.81655 6.03676 1.05543C7.04126 0.294302 8.23106 0.0101486 9.42086 0.0101486L9.43061 0Z"
            fill="currentColor"
        />
    </svg>
)

</script>

<style lang="scss" scoped>

</style>
