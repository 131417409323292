<template>
    <div class="mon-socials">
        <UiButtonIcon
            v-for="(social, index) in socials"
            :key="index"
            size="small"
            class="mon-socials__link"
            :class="`mon-socials__link--${social.id}`"
            :to="social.url"
            target="_blank"
            rel="noopener noreferrer nofollow"
            external
        >
            <span class="visually-hidden">
                {{ social.ariaLabel }}
            </span>
            <component
                :is="social.icon"
                class="text-black"
                :width="social.width"
                :height="social.height"
                aria-hidden
            />
        </UiButtonIcon>
    </div>
</template>

<script lang="ts" setup>
import { IconFacebook, IconInstagram, IconYouTube } from '#components'

const { t } = useI18n()

interface SocialsLink {
    id: string
    url: string
    ariaLabel: string
    icon: unknown,
    width: number,
    height: number,
}

const appConfig = useAppConfig()

const socials = computed<SocialsLink[]>(() => [
    {
        id: 'facebook',
        url: appConfig.links.socials.facebook,
        ariaLabel: getAriaLabelForSocial(t('accessibility.socials.facebook')),
        icon: IconFacebook,
        width: 13,
        height: 25,
    },
    {
        id: 'youtube',
        url: appConfig.links.socials.youtube,
        ariaLabel: getAriaLabelForSocial(t('accessibility.socials.you_tube')),
        icon: IconYouTube,
        width: 34,
        height: 34,
    },
    {
        id: 'instagram',
        url: appConfig.links.socials.instagram,
        ariaLabel: getAriaLabelForSocial(t('accessibility.socials.instagram')),
        icon: IconInstagram,
        width: 25,
        height: 25,
    },
])

/**
 * Returns the translated aria label for provided social network.
 * The provided parameter must be a translated string.
 *
 * @example
 * getAriaLabelForSocial(t('accessibility.instagram'))
 * // returns "Go to our Instagram (Opens in new tab)"
 * @param social
 */
function getAriaLabelForSocial(social: string) {
    return `${t('accessibility.socials.go_to_our', [social])} (${t('accessibility.opens_in_new_tab')})`
}

</script>

<style lang="scss" scoped>
.mon-socials {
    display: flex;
    flex-wrap: wrap;
    gap: 1.25rem;
    margin: -1rem 0;

    @include more-than(lg) {
        gap: 3.125rem;
    }
}

.mon-socials__link--facebook {
    margin: 0 -10px; // -(icon size(32px) - facebook icon width(13px) / 2)

    @include more-than(lg) {
        margin: 0 -18px; // -(icon size(48px) - facebook icon width(13px) / 2)
    }
}

.mon-socials__link--youtube {
    margin: 0 2px; // (icon size(32px) - youtube icon width(34px) / 2)

    @include more-than(lg) {
        margin: 0 -7px; // -(icon size(48px) - youtube icon width(34px) / 2)
    }
}

.mon-socials__link--instagram {
    margin: 0 -4px; // -(icon size(32px) - instagram icon width(25px) / 2)

    @include more-than(lg) {
        margin: 0 -12px; // -(icon size(48px) - instagram icon width(25px) / 2)
    }
}

</style>
