import { useFocusTrap } from '@vueuse/integrations/useFocusTrap'
import type { InjectionKey, MaybeRefOrGetter } from 'vue'

interface UseNavbarOptions {
    mobileMenu: {
        contentRefName?: string
        noticeContainerRefName?: string
        closeIgnoreElements: MaybeRefOrGetter<HTMLElement[]>
        isMobileWidth: MaybeRefOrGetter<boolean>
        /**
         * The duration of the opening animation of the mobile menu in milliseconds.
         * @default 300
         */
        openDuration?: number
        /**
         * The duration of the closing animation of the mobile menu in milliseconds.
         * @default 200
         */
        closeDuration?: number
        onOpen?: () => void | Promise<void>
        onWidthChangeToMobile?: () => void | Promise<void>
        onClose?: () => void | Promise<void>
    }
}

export type NavbarInstance = {
    mobileMenuAnimation: ComputedRef<{
        isOpening: boolean
        isOpen: boolean
        isClosing: boolean
        durationOpen: number
        durationClose: number
    }>
    isMobileMenuOpen: Readonly<Ref<boolean>>
    wasMobileMenuMounted: Readonly<Ref<boolean>>
    isMobileMenuOpenSynced: ComputedRef<boolean>
    openMobileMenu: () => void
    closeMobileMenu: (force?: boolean) => void
    toggleMobileMenu: () => void
    noticeContainerRef: Ref<HTMLElement | null>
    mobileMenuContentRefName: string | undefined
    mobileMenuContentRef: Ref<HTMLElement | null>
    onMobileMenuOpen: (callback: () => void) => void
    onMobileMenuClose: (callback: () => void) => void
    isMobileWidth: ComputedRef<boolean>
}

export const UseNavbarSymbol = Symbol('UseNavbarSymbol') as InjectionKey<NavbarInstance>

export function useNavbar(mode: 'inject-only'): NavbarInstance
export function useNavbar(options: UseNavbarOptions): NavbarInstance
export function useNavbar(arg: UseNavbarOptions | 'inject-only'): NavbarInstance {
    const options = arg === 'inject-only' ? null : arg

    if (options === null) {
        const injected = inject(UseNavbarSymbol)
        if (!injected) {
            throw new Error('[useNavbar]: When using the \'inject-only\' mode, the composable must be called in a context where it was provided.')
        }

        return injected
    }

    const mainContentTopPadding = useStateMainContentTopPadding()
    const noticeContainer = ref<HTMLElement | null>(null)

    const mobileMenuContent = options.mobileMenu.contentRefName ? useTemplateRef<HTMLElement>(options.mobileMenu.contentRefName) : ref<HTMLElement | null>(null)

    // const isMobileMenuOpen = ref<boolean>(false)
    const { is: isMobileMenuOpen, was: wasMobileMenuMounted } = useLazyMount()
    // whether the mobile menu is open when taking into account the animation state - ALMOST ALWAYS USE THIS
    const isMobileMenuOpenSynced = computed<boolean>({
        get() {
            const isMobileMenuMounted = options.mobileMenu.contentRefName ? true : !!mobileMenuContent.value
            return (isMobileMenuOpen.value || _mobileMenuAnimation.isOpening || _mobileMenuAnimation.isOpen || _mobileMenuAnimation.isClosing) && isMobileMenuMounted
        },
        set(val) {
            if (val) {
                openMobileMenu()
            } else {
                closeMobileMenu()
            }
        },
    })

    const _mobileMenuAnimation = reactive({
        isOpening: false,
        isOpen: false,
        isClosing: false,
    })
    const mobileMenuAnimation = computed(() => ({
        isOpening: _mobileMenuAnimation.isOpening,
        isOpen: _mobileMenuAnimation.isOpen,
        isClosing: _mobileMenuAnimation.isClosing,
        durationOpen: options.mobileMenu?.openDuration ?? 300,
        durationClose: options.mobileMenu?.closeDuration ?? 200,
    }))

    function toggleMobileMenu() {
        if (isMobileMenuOpen.value) {
            closeMobileMenu()
        } else {
            openMobileMenu()
        }
    }

    const openCallbacks = new Set<() => void>()
    const closeCallbacks = new Set<() => void>()

    if (options.mobileMenu.onOpen) {
        openCallbacks.add(options.mobileMenu.onOpen)
    }

    if (options.mobileMenu.onClose) {
        closeCallbacks.add(options.mobileMenu.onClose)
    }

    function onMobileMenuOpen(callback: () => void) {
        if (!import.meta.client) return
        if (!getCurrentScope()) {
            throw new Error('[useNavbar]: onMobileMenuOpen must be called within the setup function')
        }
        openCallbacks.add(callback)

        onScopeDispose(() => {
            openCallbacks.delete(callback)
        })
    }

    function onMobileMenuClose(callback: () => void) {
        if (!import.meta.client) return
        if (!getCurrentScope()) {
            throw new Error('[useNavbar]: onMobileMenuClose must be called within the setup function')
        }
        closeCallbacks.add(callback)

        onScopeDispose(() => {
            closeCallbacks.delete(callback)
        })
    }

    let openingAnimationTimeout: NodeJS.Timeout
    function openMobileMenu() {
        isMobileMenuOpen.value = true

        for (const callback of openCallbacks) {
            callback()
        }

        // add padding to the content to account for the notices disappearing when the menu is open
        mainContentTopPadding.value = noticeContainer.value?.offsetHeight ? `padding-top: ${noticeContainer.value.offsetHeight}px` : undefined

        // animate the mobile menu
        _mobileMenuAnimation.isClosing = false
        if (!_mobileMenuAnimation.isOpen) {
            clearTimeout(openingAnimationTimeout)
            _mobileMenuAnimation.isOpen = true
            _mobileMenuAnimation.isOpening = true
            openingAnimationTimeout = setTimeout(() => {
                _mobileMenuAnimation.isOpening = false
            }, mobileMenuAnimation.value.durationOpen)
        }
    }

    let closingAnimationTimeout: NodeJS.Timeout
    function closeMobileMenu(force: boolean = false) {
        isMobileMenuOpen.value = false

        for (const callback of closeCallbacks) {
            callback()
        }

        // animate the mobile menu
        _mobileMenuAnimation.isOpening = false
        if (_mobileMenuAnimation.isOpen) {
            clearTimeout(closingAnimationTimeout)
            _mobileMenuAnimation.isClosing = true
            _mobileMenuAnimation.isOpen = false

            const finishClosing = () => {
                _mobileMenuAnimation.isClosing = false
                // remove the padding from the content
                mainContentTopPadding.value = undefined
            }

            if (force) {
                finishClosing()
            } else {
                closingAnimationTimeout = setTimeout(finishClosing, mobileMenuAnimation.value.durationClose)
            }
        }
    }

    // the element that was focused when the mobile menu was opened (the one that opened the mobile menu)
    let prevActiveElement: HTMLElement | null = null

    // focus trap library initialization
    const { activate: activateFocusTrap, deactivate: deactivateFocusTrap } = useFocusTrap(mobileMenuContent, {
        escapeDeactivates: false,
        allowOutsideClick: true,
        setReturnFocus: () => {
        // return the element to set the focus to, or `false` (not to focus anything)
            return prevActiveElement ?? false
        },
        fallbackFocus: () => document.body,
    })

    useManagePopupOpening(isMobileMenuOpenSynced, {
        closeCallback: closeMobileMenu,
        closeOnClickOutside: mobileMenuContent,
        ignoreElements: options.mobileMenu.closeIgnoreElements,
    }, {
        onOpen: () => {
            setTimeout(() => {
                activateFocusTrap()
            }, mobileMenuAnimation.value.durationOpen)

            // save the previously focused element, before the modal was opened (the one that opened the mobile menu)
            prevActiveElement = document.activeElement as HTMLElement
        },
        onClose: () => {
        // deactivate the focus trap when the modal is closed
            deactivateFocusTrap()
        },
    })

    const isMobileWidth = computed(() => toValue(options.mobileMenu.isMobileWidth))

    watch(isMobileWidth, (value) => {
        if (!value) {
        // close the mobile menu when the screen is no longer mobile
            closeMobileMenu(true)
        } else {
            options.mobileMenu.onWidthChangeToMobile?.()
        }
    })

    const router = useRouter()
    watch(router.currentRoute, () => {
    // close the mobile menu when the route changes
        closeMobileMenu()
    })

    // eslint-disable-next-line object-shorthand
    const output: NavbarInstance = {
        mobileMenuAnimation,
        isMobileMenuOpen: readonly(isMobileMenuOpen),
        wasMobileMenuMounted,
        isMobileMenuOpenSynced,
        openMobileMenu,
        closeMobileMenu,
        toggleMobileMenu,
        noticeContainerRef: noticeContainer,
        mobileMenuContentRefName: options.mobileMenu.contentRefName,
        mobileMenuContentRef: mobileMenuContent,
        onMobileMenuOpen,
        onMobileMenuClose,
        isMobileWidth,
    }

    provide(UseNavbarSymbol, output)

    return output
}
