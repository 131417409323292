<template>
    <div class="mon-full-layout">
        <CoreSkipToMainContent />

        <!--  NAVBAR  -->
        <NavbarComponent />

        <slot />

        <!--  FOOTER  -->
        <FooterComponent class="border-t-primary-200 mt-auto border-t border-solid" />
    </div>
</template>

<script lang="ts" setup>

const { page } = useCurrentPage()
const { t } = useI18n()

useSetPageSeoProperties(page, {
    title: t('meta.title'),
    description: t('meta.description'),
})

</script>

<style lang="scss" scoped>

.mon-full-layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    min-height: 100dvh;
}

</style>
