<template>
    <CoreNavbar :navbar="navbar" header-class="mon-navbar">
        <!--  LOGO, SEARCH, AUTH, CART (top part)  -->
        <div class="mon-navbar__section mon-navbar__section--top">
            <BaseContainerContent class="mon-navbar__content" :padding="{ vertical: 'none' }">
                <!--  LOGO  -->
                <NuxtLink :to="localePath('/')" class="mon-navbar__logo">
                    <span class="visually-hidden">
                        {{ $t('accessibility.logo_link') }}
                    </span>
                    <LogoComponent class="mon-logo" />
                </NuxtLink>

                <!--  SEARCH  -->
                <MainSearchComponent class="max-md:hidden" />

                <!--  ACTIONS  -->
                <div class="flex gap-2">
                    <UiButtonIcon
                        class="md:hidden"
                        type="button"
                        size="small"
                        :aria-label="navbar.isMobileMenuOpen.value ? $t('accessibility.close_search') : $t('accessibility.open_search')"
                        @click="navbar.toggleMobileMenu"
                    >
                        <IconSearch width="22" height="22" aria-hidden />
                    </UiButtonIcon>

                    <LangSwitcherComponent class="!hidden lg:!block" />

                    <CustomerAuthMenuBlock />

                    <CartButton />

                    <!--  MOBILE MENU BUTTON  -->
                    <UiButtonIcon
                        ref="mobileMenuButton"
                        size="small"
                        class="lg:hidden"
                        content-class="flex flex-col !gap-[5px]"
                        :aria-expanded="navbar.isMobileMenuOpen.value"
                        :aria-controls="navId"
                        :aria-label="$t('accessibility.open_mobile_menu')"
                        @click="navbar.openMobileMenu"
                    >
                        <div v-for="_ in 3" :key="_" class="h-[2px] w-[20px] rounded-full bg-black" />
                    </UiButtonIcon>
                </div>
            </BaseContainerContent>
        </div>

        <!--  NAVIGATION (bottom part)  -->
        <div class="mon-navbar__section mon-navbar__section--bottom">
            <BaseContainerContent
                tag="nav"
                class="mon-navbar__content"
                :padding="{ vertical: 'none' }"
                :aria-label="$t('accessibility.primary_navigation')"
            >
                <ul class="mon-navbar__menu">
                    <NavbarLinkMain
                        v-for="(category, index) in categories.normal"
                        :key="category.id!"
                        ref="navbarLinks"
                        :to="category.getUrl() ?? ''"
                        :inset-link="index === 0 ? 'left' : undefined"
                        :label="category.name ?? ''"
                        :sub-categories="category.children"
                        @opened="() => submenuOpened(index)"
                        @closed="submenuClosed"
                    >
                        {{ category.name }}
                    </NavbarLinkMain>
                </ul>
                <ul class="mon-navbar__menu mon-navbar__menu--colored">
                    <li
                        v-for="(category, index) in categories.withColor"
                        :key="category.id!"
                        :class="{
                            'ml-auto': index === 0
                        }"
                    >
                        <NavbarLinkColor
                            :color="category.color"
                            :to="category.getUrl() ?? ''"
                        >
                            {{ category.name }}
                        </NavbarLinkColor>
                    </li>
                </ul>
            </BaseContainerContent>
        </div>

        <BaseFullscreenBackground class="mon-navbar__bg" :is-visible="isSomeSubmenuOpen" />

        <!-- ------------------------------------------------------------------------ -->
        <!--  MOBILE MENU  -->
        <LazyNavbarMobileMenu
            v-if="navbar.wasMobileMenuMounted.value"
            :id="navId!"
            class="z-10"
            :open="navbar.isMobileMenuOpen.value"
            :categories="categories.normal"
            :categories-special="categories.withColor as InstanceType<typeof CategoryTreeModel>[]"
        />

    </CoreNavbar>
</template>

<script lang="ts" setup>
import type { RequireApiModelFields } from '@composable-api/api.model'
import type { NavbarLinkMain } from '#components'

const isMobileWidth = useScssBreakpoints().smaller('lg')
const localePath = useLocalePath()

const navId = useId()

const isSomeSubmenuOpen = ref<boolean>(false)
const navbarLinks = useTemplateRefsList<InstanceType<typeof NavbarLinkMain>>()

// submenu open/close state is implemented with a timeout to prevent
// the background from flickering when the mouse moves over the menu items quickly
let closeSubmenuTimeout: NodeJS.Timeout | undefined
function submenuOpened(index: number) {
    clearTimeout(closeSubmenuTimeout)
    isSomeSubmenuOpen.value = true

    // close other submenus (if they were open using keyboard)
    for (const [i, link] of navbarLinks.value.entries()) {
        if (i === index) continue
        link.closeSubmenu()
    }
}

function submenuClosed() {
    closeSubmenuTimeout = setTimeout(() => {
        isSomeSubmenuOpen.value = false
    }, 10)
}

const navbar = useNavbar({
    mobileMenu: {
        closeIgnoreElements: [],
        isMobileWidth: isMobileWidth,
    },
})

const { items } = useCategoriesTreeApiService()
    .onlyAttrs([
        CategoryTreeModel.ATTR_ID,
        CategoryTreeModel.ATTR_NAME,
        CategoryTreeModel.ATTR_PARENT_ID,
        CategoryTreeModel.ATTR_COLOR,
        CategoryTreeModel.ATTR_CHILDREN,
    ])
    .embed([
        CategoryTreeModel.EMBED_URLS,
    ])
    .sortByAsc(CategoryTreeModel.ATTR_POSITION)
    .useGet({
        ssrCache: {
            key: 'categories-tree-menu',
            ttl: 60 * 30,   // 30 min
            swr: true,
        },
        excludeFromDataRefresh: true,
    })

const categories = computed(() => {
    const normal: InstanceType<typeof CategoryTreeModel>[] = []
    const withColor: RequireApiModelFields<InstanceType<typeof CategoryTreeModel>, 'color'>[] = []

    for (const item of items.value) {
        if (item.color) {
            withColor.push(item as RequireApiModelFields<InstanceType<typeof CategoryTreeModel>, 'color'>)
        } else {
            normal.push(item)
        }
    }

    return {
        normal,
        withColor,
    }
})

</script>

<style lang="scss" scoped>
$menu-item-gap: 0.5rem;

.mon-logo {
    width: 4.375rem;

    @include more-than(lg) {
        width: 8.125rem;
    }
}

.mon-navbar__section {
    background-color: $mon-navbar-bg;
}

.mon-navbar__section--top {
    height: $mon-navbar-mobile-top-height;

    @include more-than(lg) {
        height: $mon-navbar-top-height;
    }
}

.mon-navbar__section--bottom {
    height: $mon-navbar-bottom-height;
    overflow-x: auto;
}

.mon-navbar__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}

.mon-navbar__section--top .mon-navbar__content {
    gap: 2rem;
}

.mon-navbar__section--bottom .mon-navbar__content {
    position: relative;
    gap: $menu-item-gap;
}

.mon-navbar__menu {
    display: flex;
    align-items: center;
    height: 100%;
}

.mon-navbar__menu--colored {
    gap: $menu-item-gap;
}

.mon-navbar__bg {
    z-index: -2;
}


</style>
