<template>
    <footer class="mon-footer">
        <CoreContainer>
            <BaseContainerContent :padding="{ vertical: 'small' }" class="flex items-center justify-between">
                <!--  LANGUAGE SWITCHER  -->
                <LangSwitcherComponent variant="full" />

                <!--  SOCIALS  -->
                <SocialsComponent />
            </BaseContainerContent>
        </CoreContainer>

        <CoreContainer class="bg-black text-white" :aria-label="$t('accessibility.footer')">
            <BaseContainerContent>
                <!--  Company Logo  -->
                <NuxtLink :to="localePath('/')" class="inline-flex">
                    <span class="visually-hidden">
                        {{ $t('accessibility.logo_link') }}
                    </span>
                    <LogoComponent class="mon-footer__logo" />
                </NuxtLink>

                <div class="mon-footer__cols">
                    <!--  CONTACT  -->
                    <div class="mon-text-base">
                        <h5 class="mon-h5">
                            {{ $t('footer.col_1.title') }}
                        </h5>
                        <div class="mt-4 flex flex-col">
                            <div class="flex flex-wrap">
                                <i18n-t keypath="footer.col_1.text"
                                        tag="p"
                                        scope="global"
                                >
                                    <template #address_1>
                                        {{ $t('footer.col_1.address_1') }}
                                    </template>
                                    <template #address_2>
                                        <span class="text-nowrap">
                                            {{ $t('footer.col_1.address_2') }}
                                        </span>
                                    </template>
                                </i18n-t>
                            </div>

                            <ContactComponent />
                        </div>
                    </div>

                    <FooterMenu
                        v-for="(menu, index) in footerMenus"
                        :key="index"
                        :menu="menu"
                    />
                </div>
            </BaseContainerContent>
        </CoreContainer>

        <FooterSignature class="mon-footer__signature" />

        <IconO class="mon-footer__o" width="358" height="338" />
    </footer>
</template>

<script lang="ts" setup>

const { t } = useI18n()
const localePath = useLocalePath()

export interface FooterNavigationLink {
    label: string
    url?: string | null
    ariaLabel?: string
}

export interface FooterNavigationList {
    title: string
    links: FooterNavigationLink[]
}

// TODO: replace with correct links
const footerMenus = computed<FooterNavigationList[]>(() => [
    {
        title: t('footer.menu_1.title'),
        links: [
            {
                label: t('footer.menu_1.link_1.label'),
                url: '',
            },
            {
                label: t('footer.menu_1.link_2.label'),
                url: '',
            },
            {
                label: t('footer.menu_1.link_3.label'),
                url: '',
            },
        ],
    },
    {
        title: t('footer.menu_2.title'),
        links: [
            {
                label: t('footer.menu_2.link_1.label'),
                url: '',
            },
            {
                label: t('footer.menu_2.link_2.label'),
                url: '',
            },
            {
                label: t('footer.menu_2.link_3.label'),
                url: '',
            },
            {
                label: t('footer.menu_2.link_4.label'),
                url: '',
            },
        ],
    },
    {
        title: t('footer.menu_3.title'),
        links: [
            {
                label: t('footer.menu_3.link_1.label'),
                url: '',
            },
            {
                label: t('footer.menu_3.link_2.label'),
                url: '',
            },
            {
                label: t('footer.menu_3.link_3.label'),
                url: '',
            },
            {
                label: t('footer.menu_3.link_4.label'),
                url: '',
            },
        ],
    },
])

</script>

<style lang="scss" scoped>

.mon-footer {
    position: relative;
    overflow: hidden;
}

.mon-footer__logo {
    width: 4.375rem;
    color: $mon-c-white;

    @include more-than-custom(md) {
        width: 5.125rem;
    }

    @include more-than(lg) {
        width: 8.125rem;
    }
}

.mon-footer__cols {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem 0.625rem;

    margin-top: 1.25rem;

    @include more-than(md) {
        grid-template-columns: 2.5fr 2fr 2fr 2.5fr;
        grid-template-rows: 1fr;
        gap: 2rem 0.625rem;

        margin-top: 1.875rem;
    }

    @include more-than(lg) {
        grid-template-columns: 4fr 2fr 2fr 3fr;
    }

    @include more-than(xxl) {
        grid-template-columns: repeat(4, 1fr);
        gap: 2rem;
    }
}

.mon-footer__signature {
    border-top: 1px solid rgba(155 155 155 / 0.1);
    position: relative;
}

.mon-footer__o {
    position: absolute;
    right: -5.5rem;
    bottom: -3.375rem;

    width: 11.875rem;
    height: 11.25rem;

    color: $mon-c-white;
    opacity: 0.15;

    @include more-than(md) {
        right: -6.125rem;
        bottom: -1rem;

        width: 188.23px;
        height: 178.1px;
    }

    @include more-than(xxl) {
        right: -12.25rem;
        bottom: -3rem;

        width: 22.375rem;
        height: 21.125rem;
    }
}

</style>
