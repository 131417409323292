<template>
    <li class="mon-popup-card__list-item">
        <component :is="tag"
                   class="mon-popup-card__list-item-btn"
                   :href="href"
                   v-bind="tag === BaseUiButton ? {
                       variant: 'bare',
                       align: 'left',
                       to: href,
                       onClick: handleClick,
                   } : {}"
                   @click="handleClick"
        >
            <slot />
        </component>
    </li>
</template>

<script lang="ts" setup>
import { BaseUiButton, NuxtLink } from '#components'
import { SymbolUiPopupCard, type UiPopupCardProvide } from '@theme-types/components/UiPopupCard'

const {
    href,
    onClick,
    noAutoClose,
    normalAnchor,
} = defineProps<{
    href?: string
    onClick?: () => void | Promise<void>
    noAutoClose?: boolean
    /**
     * If `true`, the component will render an `<a>` tag instead of a `<NuxtLink>` component.
     */
    normalAnchor?: boolean
}>()

const tag = computed(() => {
    if (href && normalAnchor) {
        return 'a'
    }

    return BaseUiButton
})

const injected = inject<UiPopupCardProvide>(SymbolUiPopupCard)

async function handleClick() {
    await onClick?.()

    if (noAutoClose) return
    injected?.closePopup()
}

</script>

<style lang="scss" scoped>

$padding-inline: 1.625rem;

.mon-popup-card__list-item {
    margin-inline: -$padding-inline;
}

.mon-popup-card__list-item-btn {
    width: 100%;
    text-align: left;

    padding-block: 0.3125rem;
    padding-inline: $padding-inline;

    transition: background-color $mon-trans-time-normal $mon-timing;

    &:hover {
        background-color: $mon-c-basic-100;
        transition-duration: $mon-trans-time-short;
    }

    @include mon-text-small;

    &.router-link-active {
        @include mon-font-bold;
    }
}

</style>
