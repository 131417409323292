<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130 70" fill="none">
        <g fill="currentColor" fill-rule="evenodd">
            <path
                d="M41.005 31.257v-19.06c0-4.686-.72-6.248-3.294-6.248-1.257 0-2.515.3-3.715.906.116.839.182 1.744.182 2.766v21.636H22.203v-19.06c0-4.686-.72-6.248-3.293-6.248-1.2 0-2.392.24-3.534.781v24.527H3.41V8.35L0 5.41 15.384 0v6.007C20.234 2.102 23.411.3 26.763.3c4.072 0 6.347 2.044 7.125 5.95 5.023-4.089 8.26-5.95 11.669-5.95 5.09 0 7.423 3.182 7.423 9.322v21.636H41.005ZM117.446 31.257v-19.06c0-4.686-1.142-6.248-3.591-6.248-1.2 0-2.392.366-3.592.906v24.402H98.288V8.35l-3.41-2.95 15.385-5.408v6.007C114.152 2.634 117.628.29 121.7.29c5.089 0 7.721 3.182 7.721 9.322V31.25h-11.975v.008ZM75.042.598c-9.756 0-16.46 6.855-16.46 15.446 0 9.68 7.904 15.811 16.527 15.811 11.378 0 16.94-7.029 16.94-15.811 0-9.439-7.126-15.446-17.007-15.446Zm9.923 26.97C80.223 28.839 65.567 14.04 64.052 8.35c-.48-1.803.116-3.082 1.556-3.465 5.09-1.362 19.638 14.208 20.937 19.085.522 1.978-.025 3.174-1.58 3.598ZM96.534 54.197c0 9.68 7.903 15.811 16.526 15.811 11.379 0 16.94-7.029 16.94-15.81 0-9.44-7.125-15.447-17.006-15.447-9.757 0-16.46 6.855-16.46 15.446Zm7.026-11.158c5.089-1.363 19.638 14.207 20.937 19.085.521 1.977-.025 3.173-1.581 3.597-4.742 1.271-19.398-13.526-20.912-19.218-.48-1.803.116-3.082 1.556-3.464ZM75.58 38.743c-9.88 0-17.006 6.007-17.006 15.446 0 8.774 5.57 15.811 16.94 15.811 8.623 0 16.526-6.132 16.526-15.811 0-8.6-6.703-15.446-16.46-15.446Zm10.998 7.76c-1.514 5.692-16.17 20.49-20.912 19.218-1.564-.415-2.11-1.62-1.58-3.597 1.299-4.878 15.847-20.448 20.937-19.085 1.448.39 2.035 1.661 1.555 3.464ZM51.647 38.834c-3.45 0-5.594 1.57-6.976 3.623V38.51l-15.384 5.168 3.41 2.95V69.41H44.67V51.306c0-6.68.795-8.068 1.639-9.065-.265.714-.406 1.529-.406 2.434 0 3.606 2.276 5.833 5.743 5.833 3.468 0 5.744-2.219 5.744-5.833 0-3.614-2.276-5.832-5.744-5.832v-.009Z"
            />
        </g>
    </svg>
</template>

<script lang="ts" setup>

</script>

<style lang="scss" scoped>

</style>
