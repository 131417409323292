<template>
    <li
        ref="referenceEl"
        class="relative h-full"
        @mouseout="closeSubmenu"
    >

        <div
            class="mon-nav-link"
            :class="{
                [`mon-nav-link--inset-${insetLink}`]: insetLink,
                'underline': isSubmenuOpen,
            }"
        >
            <NuxtLink
                class="absolute inset-0 z-10"
                :to="to"
                :aria-expanded="hasSubLinks ? isSubmenuOpen : undefined"
            />

            <span>
                <slot />
            </span>

            <button
                v-if="hasSubLinks && !ssrSafeIsMobileWidth"
                ref="submenuButton"
                type="button"
                :class="{
                    'ml-1': isSubmenuButtonFocused
                }"
                :aria-expanded="isSubmenuOpen"
                :aria-label="isSubmenuOpen ? $t('accessibility.close_submenu_for', [label]) : $t('accessibility.open_submenu_for', [label])"
                @click="() => isSubmenuOpen = !isSubmenuOpen"
            >
                <IconChevron
                    v-if="isSubmenuButtonFocused"
                    aria-hidden="true"
                    :size="10"
                    :rotate="isSubmenuOpen ? 180 : 0"
                />
            </button>
        </div>

        <!--  position: fixed wrapper is needed to prevent the submenu from being cut off by overflow: hidden parent  -->
        <div class="fixed">
            <BaseUiPopup
                v-if="hasSubLinks"
                v-slot="{ rootAttrs, isShown }"
                v-model="isSubmenuOpen"
                placement="bottom"
                :reference="reference"
                :offset="0"
                :safe-padding="{
                    bottom: 16,
                }"
                :ignore-elements="[$refs.submenuButton]"
                render-method="display"
                as-child
            >
                <div v-show="isShown" class="mon-nav-link__submenu" v-bind="rootAttrs">
                    <BaseContainerContent
                        class="w-full"
                        :padding="{ vertical: 'none' }"
                    >
                        <ul class="w-full columns-3">
                            <li v-for="category in subCategories" :key="category.id!" class="mb-4 inline-block w-full">
                                <NavbarLinkBasic :to="category.getUrl() ?? ''">
                                    {{ category.name }}
                                </NavbarLinkBasic>

                                <ul v-if="category.children?.length" class="mt-3 flex flex-col gap-1">
                                    <li v-for="subCategory in category.children" :key="subCategory.id!">
                                        <NavbarLinkBasic :to="subCategory.getUrl() ?? ''" variant="secondary">
                                            {{ subCategory.name }}
                                        </NavbarLinkBasic>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </BaseContainerContent>
                </div>
            </BaseUiPopup>
        </div>
    </li>
</template>

<script lang="ts" setup>
import { NuxtLink } from '#components'
import type { NuxtLinkProps } from 'nuxt/app'
import { useFocusWithin } from '@core/app/composables/useFocusWithin'

const {
    to,
    insetLink,
    label,
    subCategories,
} = defineProps<{
    to: NuxtLinkProps['to']
    insetLink?: 'left'
    label: string
    subCategories?: InstanceType<typeof CategoryTreeModel>[]
}>()

const emit = defineEmits<{
    opened: []
    closed: []
}>()


const navbar = useNavbar('inject-only')
const hasSubLinks = computed<boolean>(() => !!subCategories?.length)

const _isSubmenuOpen = ref<boolean>(false)

const reference = useTemplateRef<HTMLLIElement>('referenceEl')

const submenuButton = useTemplateRef<HTMLButtonElement>('submenuButton')

const isHovered = hasSubLinks.value ? useElementHover(reference) : null
const { focused: isSubmenuButtonFocused } = hasSubLinks.value ? useFocus(submenuButton) : { focused: null }

const isSubmenuOpen = computed({
    get() {
        if (navbar.isMobileWidth.value) {
            return false
        }
        return _isSubmenuOpen.value || !!isHovered?.value
    },
    set(val) {
        _isSubmenuOpen.value = val
    },
})

if (hasSubLinks.value) {
    watch(isSubmenuOpen, (val) => {
        if (val) {
            emit('opened')
        } else {
            emit('closed')
        }
    })

    const { focused: isFocusWithin } = useFocusWithin(reference)
    watch(isFocusWithin, (val) => {
        if (!val) {
            isSubmenuOpen.value = false
        }
    })
}

function closeSubmenu() {
    isSubmenuOpen.value = false
}

defineExpose({
    closeSubmenu,
})

const ssrSafeIsMobileWidth = ref<boolean>(false)
onMounted(() => {
    ssrSafeIsMobileWidth.value = navbar.isMobileWidth.value
})
watch(navbar.isMobileWidth, (val) => {
    ssrSafeIsMobileWidth.value = val
})

</script>

<style lang="scss" scoped>
.mon-nav-link {
    position: relative;
    display: flex;
    align-items: center;

    height: 100%;
    color: $mon-c-black;

    padding: 0.25rem $mon-navbar-link-side-padding-mobile;
    box-sizing: border-box;
    white-space: nowrap;

    @include mon-text-small;

    @include more-than(xl) {
        padding: 0.25rem $mon-navbar-link-side-padding-desktop;
    }

    &:hover {
        text-decoration: underline;
    }
}

.mon-nav-link--inset-left {
    margin-left: calc(-1 * #{$mon-navbar-link-side-padding-mobile});

    @include more-than(xl) {
        margin-left: calc(-1 * #{$mon-navbar-link-side-padding-desktop});
    }
}

// Desktop submenu styles
.mon-nav-link__submenu {
    width: 100vw;
    padding-block: 2rem;
    background-color: $mon-navbar-bg;
    overflow-y: auto;
}

</style>
