<script lang="tsx">
import { defineComponentCoreIcon } from '@core/app/components/core/icon/CoreIcon.vue'

export default defineComponentCoreIcon(
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path
            d="M 12 3 C 9.219873 3 7.0702243 3.1849411 5.5253906 3.3945312 C 3.5192045 3.66666 2 5.3798482 2 7.4101562 L 2 16.589844 C 2 18.620928 3.5195005 20.33338 5.5253906 20.605469 C 7.0706661 20.816142 9.219873 21 12 21 C 14.780127 21 16.929776 20.815059 18.474609 20.605469 C 20.481362 20.333429 22 18.620627 22 16.589844 L 22 7.4101562 C 22 5.3790719 20.4805 3.6666199 18.474609 3.3945312 C 16.929776 3.184941 14.780127 3 12 3 z M 12 4.5 C 14.713873 4.5 16.796271 4.6804497 18.273438 4.8808594 C 19.555547 5.0547709 20.5 6.1212407 20.5 7.4101562 L 20.5 16.589844 C 20.5 17.879061 19.556685 18.945181 18.273438 19.119141 C 16.796271 19.31955 14.713873 19.5 12 19.5 C 9.286127 19.5 7.203287 19.320468 5.7265625 19.119141 C 4.4444527 18.945229 3.5 17.878759 3.5 16.589844 L 3.5 7.4101562 C 3.5 6.1224643 4.4447486 5.0547307 5.7265625 4.8808594 C 7.2037288 4.6804497 9.286127 4.5 12 4.5 z M 10.917969 8.8125 C 10.439 8.8169531 10 9.2012813 10 9.7382812 L 10 14.263672 C 10 14.979672 10.778531 15.423594 11.394531 15.058594 L 15.214844 12.796875 C 15.818844 12.437875 15.818844 11.563078 15.214844 11.205078 L 11.394531 8.9433594 C 11.240531 8.8521094 11.077625 8.8110156 10.917969 8.8125 z"
        />
    </svg>
)

</script>

<style lang="scss" scoped>

</style>
