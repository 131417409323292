<template>
    <ul class="mon-popup-card__list">
        <slot />
    </ul>
</template>

<script lang="ts" setup>

</script>

<style lang="scss" scoped>

.mon-popup-card__list {
    display: flex;
    flex-direction: column;
}

</style>
