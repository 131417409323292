<template>
    <CoreContainer :aria-label="$t('accessibility.copyright')" class="bg-black text-white">
        <BaseContainerContent :padding="{ vertical: 'none' }" class="mon-footer-signature__content">
            <div class="mon-footer-signature__cols">
                <div class="mon-footer-signature__col mon-footer-signature__col--links gap-1">
                    <a :href="staticPageUrl('gdpr')" class="mon-hover-link">
                        {{ $t('footer.signature.links.gdpr') }}
                    </a>
                    <a :href="staticPageUrl('cookies')" class="mon-hover-link">
                        {{ $t('footer.signature.links.cookies') }}
                    </a>
                </div>

                <div class="mon-footer-signature__col mon-footer-signature__col--icons gap-4">
                    <span class="mon-footer-signature__payment">
                        <CoreImg src="/images/icons/visa.svg"
                                 alt="Visa"
                                 loading="lazy"
                        />
                    </span>
                    <span class="mon-footer-signature__payment">
                        <CoreImg src="/images/icons/mastercard.svg"
                                 alt="MasterCard"
                                 loading="lazy"
                        />
                    </span>
                    <span class="mon-footer-signature__payment">
                        <CoreImg src="/images/icons/apple-pay.svg"
                                 alt="Visa"
                                 loading="lazy"
                        />
                    </span>
                    <span class="mon-footer-signature__payment">
                        <CoreImg src="/images/icons/google-pay.svg"
                                 alt="Visa"
                                 loading="lazy"
                        />
                    </span>
                </div>

                <div class="mon-footer-signature__col mon-footer-signature__col--info gap-1">
                    <span>
                        {{ $t('footer.signature.copyright') }}
                    </span>
                    <span>
                        {{ $t('footer.signature.identification') }}
                    </span>
                </div>

                <CoreCreatedBySimplo
                    class="mon-footer-signature__col mon-footer-signature__col--simplo"
                    variant="light"
                />
            </div>
        </BaseContainerContent>
    </CoreContainer>
</template>

<script lang="ts" setup>

const runtimeConfig = useRuntimeConfig()
const { getDynamicRoute } = useDynamicRoutes()

const staticPages = runtimeConfig.public.staticPageIds
const staticPageUrl = (key: keyof typeof staticPages): string => {
    const id = Number(staticPages[key])
    return getDynamicRoute('static-page', id) as string
}

</script>

<style lang="scss" scoped>

.mon-footer-signature__content {
    padding-top: 1.25rem;
    padding-bottom: 1.875rem;

    @include mon-text-extra-small;
}

.mon-footer-signature__cols {
    display: flex;
    flex-direction: column;

    @include more-than(md) {
        display: grid;
        grid-template-areas: "links info icons"
                             "links simplo icons";
        justify-content: space-between;
    }

    @include more-than(lg) {
        grid-template-columns: 4fr 2fr 2fr 3fr;
        grid-template-areas: "info links simplo icons";
        gap: 2rem 0.625rem;
    }

    @include more-than(xxl) {
        grid-template-columns: repeat(4, 1fr);
        gap: 2rem;
    }
}

.mon-footer-signature__col {
    display: flex;
}

.mon-footer-signature__col--links {
    flex-direction: column;
    grid-area: links;

    @include mon-text-base;

    @include less-than(lg) {
        margin-bottom: 1.75rem;
    }

    @include more-than(xl) {
        @include mon-text-extra-small;
    }
}

.mon-footer-signature__col--icons {
    grid-area: icons;

    @include less-than(lg) {
        margin-bottom: 1.25rem;
    }
}

.mon-footer-signature__payment {
    height: 2.125rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-inline: 0.625rem;
    background-color: $mon-c-white;
    border-radius: 0.25rem;
}

.mon-footer-signature__col--info {
    flex-direction: column;
    grid-area: info;

    @include less-than(xl) {
        margin-bottom: 0.5rem;
    }
}

.mon-footer-signature__col--simplo {
    grid-area: simplo;

    @include more-than(md) {
        height: min-content;
    }
}

</style>
